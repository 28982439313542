import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BenefitsService } from '@services/benefits.service';
import { OnboardingFormStateService } from '@services/form-state/onboarding-form.state.service';
import { IS_ENTITY_OWNED, ONBOARDING_CODE_KEY } from '@utils/constants';
import { LOCAL_STORAGE_TOKEN } from 'src/app/utils/tokens';
import { environment } from 'src/environments/environment';

import { PolicyPreview } from './types';
import { AccountCreateResponse, MfaAuthRequest, MfaAuthResponse } from './types/api-responses';

// This API Service does not extend the base ApiSevice.
@Injectable({
  providedIn: 'root',
})
export class GettingStartedService {
  private associatedEmail: string;
  private _isPolicyEntityOwned: boolean;

  constructor(
    protected http: HttpClient,
    @Inject(LOCAL_STORAGE_TOKEN) private storage: Storage,
    private benefitsService: BenefitsService,
    private onboardingFormStateService: OnboardingFormStateService
  ) {}

  getAssociatedEmail() {
    return this.associatedEmail;
  }

  isPolicyEntityOwned(): boolean {
    return this._isPolicyEntityOwned ?? this.storage.getItem(IS_ENTITY_OWNED) === 'true';
  }

  exchangeCode(code): Observable<PolicyPreview> {
    return this.http
      .post<PolicyPreview>(`${environment.baseApiUrl}/verify`, {
        code,
      })
      .pipe(
        map((result: any) => {
          const appDetails = result.appDetails;
          this._isPolicyEntityOwned = appDetails.primarySigner != null ? true : false;
          // Save the value to local storage so it can be accessed when page reloads
          this.storage.setItem(IS_ENTITY_OWNED, this._isPolicyEntityOwned.toString());
          this.associatedEmail = appDetails.owner.email ?? appDetails.primarySigner.email;
          appDetails.expiration = appDetails.expiration * 1000;
          appDetails.benefits = appDetails.benefits.map((benefit) => {
            return this.benefitsService.getBenefitDetails(benefit);
          });

          this.onboardingFormStateService.setPolicyPreview(appDetails);

          return appDetails;
        })
      );
  }

  saveCode(code) {
    this.storage.setItem(ONBOARDING_CODE_KEY, code);
    return of({});
  }

  getCode() {
    return this.storage.getItem(ONBOARDING_CODE_KEY);
  }

  // path will need to be verified and maybe changed
  declinePlan(code) {
    return this.http.post(`${environment.baseApiUrl}/decline-plan`, {
      code,
    });
  }

  saveSurveyFeedbackToDB(policyId: string, surveyFeedback, code: string) {
    const postBody = {
      status: 'declined',
      surveyResponse: surveyFeedback,
      code,
    };
    return this.http.post(
      `${environment.baseApiUrl}/onboarding/${policyId}/survey`,
      postBody
    );
  }

  requestNewEmailLink(code: string) {
    return this.http.post(
      `${environment.baseApiUrl}/onboarding/request-new-link`,
      {
        code,
      }
    );
  }

  verifyIdentity(ssnDigits: string, code?: string) {
    let onboardingCode = code;
    if (!onboardingCode) {
      onboardingCode = this.getCode();
    }

    if (!onboardingCode) {
      throw new Error('No onboarding code on verify-identity page.');
    }

    return this.http.post(
      `${environment.baseApiUrl}/onboarding/verify-identity`,
      {
        code: onboardingCode,
        ssnDigits,
      }
    );
  }

  sendMfaCode(requestBody: MfaAuthRequest): Observable<MfaAuthResponse> {
    // TODO(davmau): validate requestBody?
    return this.http.post<MfaAuthResponse>(`${environment.baseApiUrl}/onboarding/mfa`, {
      ...requestBody
    })
  }

  register(email: string, password: string, code?: string): Observable<AccountCreateResponse> {
    if (!code) {
      code = this.getCode();
    }
    return this.http.post<AccountCreateResponse>(`${environment.baseApiUrl}/onboarding/account`, {
      code,
      email,
      password,
    });
    // return of<AccountCreateResponse>(
    //   { responseType: 'MFA_CHALLENGE',
    //     challengeResponse: {
    //       ChallengeName: 'EMAIL_OTP',
    //       Session: 'AYABeF6JUiQIBlASLM8Xn7PTIpIAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOnVzLXdlc3QtMjowMTU3MzY3MjcxOTg6a2V5LzI5OTFhNGE5LTM5YTAtNDQ0Mi04MWU4LWRkYjY4NTllMTg2MQC4AQIBAHhPj7k9zU4nGXUQUvM0Ccwk42DS-fm3vKmH75ktTrktNQElglYu4qp1rMhnF_yHvQ81AAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQM1XoRHUbh9SnC00HDAgEQgDvy6nJ-FJYRlQfFSklUJn4T_KtoK9SCDNeDKHt_p2VfTBvgZ-ajPCz4VIJct46iICrNH4gTDQRpdNUOQAIAAAAADAAAEAAAAAAAAAAAAAAAAACdOxYmUaZNGkWJ2otcN65D_____wAAAAEAAAAAAAAAAAAAAAEAAAI2o3T0YwLE2WAINZvvtg7feGKzOMV9xPZQ4BQb2VZ9ImZDsDBw5RwI6MLatvRlV5923RkVxMpYgph8d7QD5vuERfYRFbNCA9npcEtyaNX9zv-m8FXQSOGTXSzCfZzMwOHx0IXc3L8CxjTK1xlfEO2DpZm8Hxjvwh3njEqp8pDjz4GdkK1Vg8mPrTBg9EPfb5U0dUF3WnPWOasMmPaQqPzph_Iex3b72vMXGMsGdbFSAPIm_xlfSda98Ba2sPviYaEY4j6p5GFbTHSBr7Lya2HXxv0gGtS4pbwJWSH0fk78ADTZtJioWNH80ndH5uYrhxxPRkqFvR2qRgszJCbfbSaMTq2Rb1O355OuHDwya4StIjNszIZgue0CPRYyiknRY3C8bCFEvQIY_S75B-QCI_4zR9k_IHxSmVRfccsJodki6c0y_LFiBO7v_K7he6glShJ1443By-P5TYVy9Q4AkENLUuow_RCINmZAAYkc9RLv7qbCT4SJ4bY_HBsMM5p_3fyxowaryhYlNtcis1kuuJdhRiwwiAB6b9edMp56ThOLQ95Zo-lAkwDd6Q2JBggpNWei6E_ken0m67bJrOhQ3N8CUony-onrD2wvmidZt0mNsDVqO7gwtJvsj7bOeryuRETsoxSR1HxJbWRcsatIpXodOsJyzRWe3szVAGwG5UmMuwtkvDjVXg-b16FVmVJPpczTNWJrz6SzRQUBQ31uRHHCAMsjtuoInvdiaySH_epEMMf_TpXoA7AIbx_O5muvdSzGLm_osD09',
    //       ChallengeParameters: {
    //         CODE_DELIVERY_DELIVERY_MEDIUM: 'EMAIL',
    //         CODE_DELIVERY_DESTINATION: 'a***@g***',
    //         USER_ID_FOR_SRP: '3197418b-31e5-483e-8387-9ab7797f33d0'
    //       }
    //     }
    //   }
    // )
  }

  login(code: string, email: string, password: string) {
    return this.http.post(`${environment.baseApiUrl}/onboarding/login`, {
      code,
      email,
      password,
    });
  }
}
